// Font Awesome
// Font icon set

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faCalendarAlt,
  faChevronRight,
  faClock,
  faEnvelope,
  faExternalLinkAlt,
  faInfo,
  faGlobe,
  faMapMarkerAlt,
  faMinus,
  faPhone,
  faPhoneAlt,
  faPlus,
  faPrint,
  faSearch,
  faSort,
  faTimes,
  faLongArrowAltRight,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faPinterest,
  faPinterestP,
  faTwitter,
  faYoutube,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

$(document).ready(function () {
  library.add(
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faArrowDown,
    faCalendarAlt,
    faChevronRight,
    faClock,
    faEnvelope,
    faExternalLinkAlt,
    faFacebook,
    faFacebookF,
    faInfo,
    faGlobe,
    faInstagram,
    faLinkedinIn,
    faMapMarkerAlt,
    faMinus,
    faPhone,
    faPhoneAlt,
    faPinterest,
    faPinterestP,
    faPlus,
    faPrint,
    faSearch,
    faSort,
    faTimes,
    faLongArrowAltRight,
    faPaperPlane,
    faTwitter,
    faYoutube,
    faLinkedin,
    faTiktok,
  );

  dom.i2svg();
});
